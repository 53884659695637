<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Manual enrolment for practical exams</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Manual enrolment
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <h6>You should be enrolling candidate on behalf of following enroller.</h6>
            <v-row>
              <v-col cols="12" md="4">
                <div class="card p-3 bg-gray-300">
                  <div><b>Full name :</b> AMEB-TEST WA</div>
                  <div><b>Email :</b> testwa@ameb.edu.au</div>
                  <div><b>Address :</b> 21 Victoria Street</div>
                </div>
              </v-col>
              <v-col cols="12" md="4">
                <div class="card p-3 bg-gray-300">
                  <div><b>Suburb/Town :</b> Perth</div>
                  <div><b>Post code :</b> 6000 </div>
                  <div><b>Phone :</b> 0406551602</div>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <p class="text-danger bold">Note: Please make sure payment is collected over the phone, or via eftpos, bank transfer etc. Manual enrolment does not include a payment, or require payment confirmation to enrol the candidate.</p>
              </v-col>
            </v-row>
            <hr>
            <v-row>
              <v-col cols="12">
                <div class="form-group d-flex justify-content-between">
                  <label class="mt-2">Has Discount? *</label>
                  <v-select
                      class="ml-10"
                      :items="hasDiscount"
                      item-text="text"
                      dense
                      outlined
                      item-value="value"
                      label="Select option"
                      required
                  ></v-select>
                </div>
              </v-col>
              <v-col cols="12">
                <div class="form-group d-flex justify-content-between">
                  <label class="mt-2">Exam *</label>
                  <v-select
                      class="ml-13"
                      :items="exams"
                      item-text="text"
                      dense
                      outlined
                      item-value="value"
                      label="Select One"
                      required
                  ></v-select>
                </div>
              </v-col>
              <v-col cols="12">
                <div class="form-group d-flex justify-content-between">
                  <label class="mt-2">Syllabus *</label>
                  <v-select
                      class="ml-9"
                      :items="syllabus"
                      item-text="text"
                      dense
                      outlined
                      item-value="value"
                      label="Select One"
                      required
                  ></v-select>
                </div>
              </v-col>
              <v-col cols="12">
                <div class="form-group d-flex justify-content-between">
                  <label class="mt-2">Location *</label>
                  <v-select
                      class="ml-9"
                      :items="location"
                      item-text="text"
                      dense
                      outlined
                      item-value="value"
                      label="Select One"
                      required
                  ></v-select>
                </div>
              </v-col>
              <v-col cols="12">
                <div class="form-group d-flex justify-content-between">
                  <label class="mt-2">Week *</label>
                  <v-select
                      class="ml-13"
                      :items="weeks"
                      item-text="text"
                      dense
                      outlined
                      item-value="value"
                      label="Week"
                      required
                  ></v-select>
                </div>
              </v-col>
              <v-col cols="12">
                <div class="form-group">
                  <v-button
                    class="btn btn-primary btn-block btn-sm"
                  >
                    <i class="fa fa-plus"></i>
                    Add Candidate
                  </v-button>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>

export default {
  data() {
    return {
      hasDiscount: ['No', 'Yes'],
      exams: ['test 1', 'test 2', 'test 3', 'test 4'],
      syllabus: ['lorem', 'ipsum', 'test56', 'lipsum', 'lorem 12'],
      location: ['Perth', 'Canberra', 'Sydney'],
      weeks: ['Week 24', 'Week 24 - Rockschool Video Exams 2012-12-23']
    }
  },
}
</script>
